define("discourse/plugins/discourse-ip-location/discourse/templates/connectors/user-profile-secondary/ip-location-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <dl>
      <dt>{{i18n "discourse_ip_location.ip_location"}}</dt><dd
        class="ip-location"
      >{{this.model.ip_location}}</dd>
    </dl>
  </div>
  */
  {
    "id": "4n/qDt+l",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,[28,[35,0],[\"discourse_ip_location.ip_location\"],null]],[13],[10,\"dd\"],[14,0,\"ip-location\"],[12],[1,[30,0,[\"model\",\"ip_location\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ip-location/discourse/templates/connectors/user-profile-secondary/ip-location-connector.hbs",
    "isStrictMode": false
  });
});