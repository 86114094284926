define("discourse/plugins/discourse-ip-location/discourse/templates/connectors/user-card-metadata/ip-location-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>
    <span class="desc">{{i18n "discourse_ip_location.ip_location"}}</span>
    {{this.user.ip_location}}
  </h3>
  */
  {
    "id": "V9mwsqyw",
    "block": "[[[10,\"h3\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,0],[\"discourse_ip_location.ip_location\"],null]],[13],[1,\"\\n  \"],[1,[30,0,[\"user\",\"ip_location\"]]],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ip-location/discourse/templates/connectors/user-card-metadata/ip-location-connector.hbs",
    "isStrictMode": false
  });
});